<template>
    <component
        :is="element"
        v-bind="$attrs"
        class="pill"
    >
        <slot />
    </component>
</template>

<script>
export default {
    props: {
        element: {
            type: [String, Object],
            required: false,
            default: 'span'
        }
    }
};
</script>

<style lang="less">
.pill {
    .typography-headings;

    display: inline-block;
    padding: .65rem 1.25rem .5rem 1.25rem;
    font-size: .875rem;
    background-color: var(--color-secondary-light);
    line-height: 1;
    border-radius: var(--borderRadius-lg);
    text-decoration: none;

    &[href] {
        transition: color var(--transition-fast), background-color var(--transition-fast);

        &:hover,
        &:focus {
            color: var(--color-lightest);
            background-color: var(--color-secondary);
        }

        &:active {
            color: var(--color-lightest);
            background-color: var(--color-secondary-dark);
        }
    }

    @media @q-md-min {
        padding: .75rem 1.25rem .6875rem 1.25rem;
    }
}

.pill--dark {
    color: var(--color-lightest);
    background-color: var(--color-secondary-dark);
}

.pill--result {
    .typography-primary;
    padding: .6em .7em;
    font-size: var(--font-size-xs);
    color: var(--color-primary);
    background-color: var(--color-lightest);

    @media @q-md-min {
        font-size: var(--font-size-sm);
    }
}

.pill--square {
    .typography-label-secondary;
    color: var(--color-lightest);
    padding: .5rem 1.25rem .375rem;
    background-color: var(--color-secondary);
    border-radius: var(--borderRadius-xs);

    &[href] {
        &:hover,
        &:focus {
            color: var(--color-lightest);
            background-color: var(--color-secondary-dark);
        }

        &:active {
            color: var(--color-darkest);
            background-color: var(--color-secondary-light);
        }
    }
}

.pill--largest {
    .typography-h4;
    padding: .75rem 1.25rem .6875rem 1.25rem;
    font-size: 1.125rem;
}
</style>
